var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Purchase inventory","show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div')]},proxy:true},{key:"title",fn:function(){return [_c('TMessage',{staticClass:"h5 my-auto",attrs:{"content":"Product","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('span',[_vm._v(": "+_vm._s(_vm.product_id))])]},proxy:true}])})]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"noPaginate":"","store":"order.order_items_need_buy","resource":"","noFilter":"","noClickRow":""},scopedSlots:_vm._u([{key:"order_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.order)?_c('TLink',{attrs:{"content":item.order_id,"messageOptions":{ bold: true },"to":_vm.lodash.getReferenceLink('order', item.order_id)}}):_vm._e()],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.customer)?_c('TLink',{attrs:{"content":item.customer.name,"to":_vm.lodash.getReferenceLink('user', item.customer.id)}}):_vm._e()],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"currency":item.currency_id || 'JPY'}})],1)]}},{key:"remaining_distributed_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{staticClass:"h6",attrs:{"value":item.remaining_distributed_quantity}})],1)]}},{key:"quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{staticClass:"h6",attrs:{"value":item.quantity}})],1)]}},{key:"distributed_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{staticClass:"h6",attrs:{"value":item.distributed_quantity}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }