var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"product.purchase_product_statistics","reloadable":"","resource":"","noClickRow":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.refresh},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('TButtonQuickView',{staticClass:"mx-auto",on:{"click":function($event){return _vm.view(item)}}}),_c('TButtonEdit',{staticClass:"mx-auto",on:{"click":function($event){return _vm.edit(item)}}})]}},{key:"first_item",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SCardProductItems',{attrs:{"item":item.product,"resource":"goods/products"}})],1)]}},{key:"supplier",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-truncate"},[_vm._l((item.suppliers),function(supplier){return _c('div',{key:supplier.id,staticClass:"mb-2 bg-white p-1 rounded"},[_c('TLink',{attrs:{"messageOptions":{ bold: true },"content":supplier.name,"to":_vm.lodash.getReferenceLink('supplier',supplier.id)}}),_c('div',{staticClass:"d-flex"},[_c('CBadge',{staticClass:"d-flex p-1",staticStyle:{"width":"125px"},attrs:{"color":"light"}},[_c('TMessage',{attrs:{"content":"Quantity"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" / "+_vm._s(_vm.$t("Box"))+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(supplier.pivot.quantity_items))])]},proxy:true}],null,true)})],1),_c('TMessage',{attrs:{"content":"Box"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._v(" ("),_c('span',{},[_vm._v(_vm._s(Math.ceil(item.output / supplier.pivot.quantity_items))+" ")])]},proxy:true}],null,true)}),_vm._v(") ")],1)],1)}),(!item.suppliers.length)?_c('TMessageNoData',{attrs:{"iconSize":"20","spacing":"my-0","alignment":"left","size":"h4"}}):_vm._e()],2)]}},{key:"output",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{staticClass:"h6",attrs:{"value":item.output}})],1)]}},{key:"first_item-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['product_id'],"placeholder":"Jancode"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'product_id', $event)},_vm.filterChange]}})]},proxy:true},{key:"supplier-filter",fn:function(){return [_c('SSelectSupplier',{attrs:{"value":_vm.filterSupplier,"prependAll":"","noCustomLabel":"","prepend":[{ id: 'empty', name: _vm.$t('Empty') }]},on:{"update:value":_vm.updateFilterSupplier,"change":_vm.filterChange}})]},proxy:true}])}),(_vm.productSelected)?_c('SModalUpdateProductSupplier',{attrs:{"show":_vm.showModalEdit,"product_id":_vm.productSelected},on:{"update:show":function($event){_vm.showModalEdit=$event},"updated":_vm.filterChange}}):_vm._e(),(_vm.productViewSelected)?_c('PurchaseInventory',{attrs:{"show":_vm.showModalView,"product_id":_vm.productViewSelected},on:{"update:show":function($event){_vm.showModalView=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }